.footer_container {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
  padding: 1px;
  background-color: #f8f8f8;
  color: #333;
  border-radius: 0px 0px 5px 5px;
}

.footer_container a {
  text-decoration: none;
  color: #333;
}

.footer_container a:hover {
  cursor: pointer;
  text-decoration: underline;
}
