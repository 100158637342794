/* CSS para a Navbar */
.navbar {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f8f8f8;
  border-radius:  5px 5px 0px 0px;
}

.navbar img {
  width: 50px;
  margin-right: 2em;
}

.navbar ul {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  gap: 2em;
}

.navbar ul li {
  list-style: none;
  display: flex;
  align-items: center;
}

.navbar ul li a {
  font-weight: 600;
  text-decoration: none;
  color: #910a67;
  font-size: 1.2rem;
  cursor: pointer;
}

.navbar ul li a:hover {
  text-decoration: underline;
  transform: scale(1.1);
  transform: translateY(-2px);
}

/* Estilos para o menu hamburguer */
.menuToggle {
  display: none; 
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #910a67;
  cursor: pointer;
}

/* Media Queries para Responsividade */
@media (max-width: 768px) {
  .navbar {
    justify-content: space-between; 
  }

  .navbar ul {
    display: none; 
    flex-direction: column; 
    width: 100%; 
    padding: 0; 
  }

  .navbar ul.open {
    display: flex;
  }

  .menuToggle {
    display: block; 
  }
}
