.experiences_container {
  font-family: Arial, Helvetica, sans-serif;
  color: #333;
  margin-left: 15px;
  margin-bottom: 10px;
}

.experiences_container section {
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.experiences_container h1 {
  text-transform: uppercase;
  font-size: 2.2rem;
  color: #7eacb5;
  text-align: center;
  margin-bottom: 20px;
}

.experiences_container h2 {
  font-family: Arial, Helvetica, sans-serif;
  color: #910a67;
}

.experiences_container ul {
  margin-top: 20px;
  list-style-type: disc;
}

.experiences_container ul li::marker {
  color: #7eacb5; /* Define a cor do marcador da lista */
}

.experiences_container ul li {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
}
