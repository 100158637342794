

.projects_container {
  font-family: Arial, Helvetica, sans-serif;
  color: #333;
  padding: 20px;
}

.projects_container h1 {
  text-transform: uppercase;
  font-size: 2.2rem;
  color: #7eacb5;
  text-align: center;
  margin-bottom: 20px; 
}

.projects_container h2 {
  text-align: center;
  color: #910a67;
  background: linear-gradient(
    135deg,
    rgba(232, 189, 171, 0.5),
    rgba(212, 171, 199, 0.5),
    rgba(156, 192, 209, 0.5),
    rgba(126, 172, 181, 0.5)
  );
  padding: 8px;
  border-radius: 6px;
}

.projects_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); 
  gap: 15px;
}

.project_card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s; 
  padding: 15px; 

}

.project_card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); 
}

.project_card h3 {
  color: #A66589;
}

.project_card a {
text-decoration: none;
color: #7eacb5;
}

.project_card a:hover {
  color: #2a8d8e; 
  text-decoration: underline; 
}

.project_info {
  padding: 15px;
  text-align: center; 
}

.project_details {
  padding: 15px;
  background-color: #f9f9f9;
  border-top: 1px solid #eee;
  border-radius: 0 0 8px 8px; 
}

.project_card img {
width: 100%;
}
