
/* About.module.css */

.about_container {
  font-family: Arial, sans-serif;
  background: linear-gradient(135deg,
      rgba(232, 189, 171, 0.5),
      rgba(212, 171, 199, 0.5),
      rgba(156, 192, 209, 0.5),
      rgba(126, 172, 181, 0.5));
  height: auto;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 0px 0px 5px 5px;
}

.about_container main {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 768px) {
  .about_container main {
    grid-template-columns: 1fr 2fr;
  }
}

.about_container section {
  max-width: 800px;
  margin: 0 auto;
}

.about_container h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.about_container h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #7eacb5;

}

.about_container h1 strong {
  color: #910a67;
}

.about_container p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  color: #2f3645;
}

.about_container img {
  max-width: 50%;
  height: auto;
  margin-top: 1rem;
}

.linkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  padding: 0px;
  border-radius: 5px;
  color: #333;
}

.link a {
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #910a67;
}

.link i {
  font-size: 25px;
  margin-bottom: 3px;
  margin-right: 5px;
  color: #910a67;
}

.link:hover {
  border-color: rgba(126, 172, 181, 0.259);
  background: rgba(126, 172, 181, 0.17);
  transform: scale(1.1);
  transform: translateY(-2px);
}

/* Media Query */

@media (min-width: 480px) {
  .about_container main {
    grid-template-columns: 1fr 1fr;
    /* Duas colunas para telas pequenas */
  }
}

@media (min-width: 768px) {
  .about_container main {
    grid-template-columns: 1fr 2fr;
    /* Duas colunas para telas médias */
  }
}

@media (min-width: 1024px) {
  .about_container main {
    grid-template-columns: 1fr 3fr;
    /* Colunas mais largas para telas grandes */
  }
}
