/* Skills.module.css */

.skills {
  padding: 2rem;
  font-family: Arial, Helvetica, sans-serif;
}

.skills_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.skills_card {
  background-color: rgba(126, 172, 181, 0.081);
  margin-bottom: 20px;
  width: 150px;
  height: 150px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(232, 189, 171, 0.5),
    /* Sombra 1 */ 0px 6px 15px rgba(212, 171, 199, 0.5),
    /* Sombra 2 */ 0px 8px 20px rgba(156, 192, 209, 0.5),
    /* Sombra 3 */ 0px 10px 25px rgba(126, 172, 181, 0.5);
  text-align: center;
}

.skills h1 {
  text-transform: uppercase;
  font-size: 2rem;
  color: #7eacb5;
  text-align: center;
}

.skills h2 {
  text-align: center;
  color: #910a67;
  background: linear-gradient(
    135deg,
    rgba(232, 189, 171, 0.5),
    rgba(212, 171, 199, 0.5),
    rgba(156, 192, 209, 0.5),
    rgba(126, 172, 181, 0.5)
  );
  padding: 8px;
  border-radius: 6px;
}

.skills span {
  color: #333;
}

.skills_container ul {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.skills_container ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.skills_container ul li img {
  max-width: 80px;
  height: auto;
  margin-bottom: 0.5rem;
}

.divider {
  margin: 2rem 0;
  border: 1px solid #ccc;
}
